// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "gap-5",
      "w-3/4"
    ]);

var required = Cx.cx([
      "after:content-['*']",
      "after:text-red"
    ]);

var fieldContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-4"
    ]);

var multiFieldContainer = Cx.cx([
      "flex",
      "gap-4",
      "w-full"
    ]);

var fieldContainer25 = Cx.cx([
      "flex",
      "flex-col",
      "gap-4",
      "w-1/4"
    ]);

var buttonContainer = Cx.cx([
      "flex",
      "flex-row-reverse",
      "gap-5"
    ]);

var sectionTitle = Cx.cx([
      "text-lg",
      "text-gray-600",
      "font-bold"
    ]);

var passwordFieldLabel = Cx.cx([
      "flex",
      "flex-row",
      "items-center",
      "gap-2",
      "cursor-pointer"
    ]);

var titleHeader = Cx.cx([
      "flex",
      "flex-col"
    ]);

var titleSpan = Cx.cx([
      "uppercase",
      "text-lg",
      "text-gray-400"
    ]);

var showLink = Cx.cx([
      "text-base",
      "text-gray-400",
      "font-medium",
      "hover:text-teal"
    ]);

var uploadDocumentButton = "mr-2.5";

export {
  container ,
  required ,
  fieldContainer ,
  multiFieldContainer ,
  fieldContainer25 ,
  buttonContainer ,
  uploadDocumentButton ,
  sectionTitle ,
  passwordFieldLabel ,
  titleHeader ,
  titleSpan ,
  showLink ,
}
/* container Not a pure module */
