// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DashboardFormCss from "./DashboardFormCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function DashboardForm$SectionTitle(props) {
  return JsxRuntime.jsx("h2", {
              children: props.children,
              className: DashboardFormCss.sectionTitle
            });
}

var SectionTitle = {
  make: DashboardForm$SectionTitle
};

function DashboardForm$FieldContainer(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: DashboardFormCss.fieldContainer
            });
}

var FieldContainer = {
  make: DashboardForm$FieldContainer
};

function DashboardForm$FieldContainer25(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: DashboardFormCss.fieldContainer25
            });
}

var FieldContainer25 = {
  make: DashboardForm$FieldContainer25
};

function DashboardForm$MultiFieldContainer(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: DashboardFormCss.multiFieldContainer
            });
}

var MultiFieldContainer = {
  make: DashboardForm$MultiFieldContainer
};

function DashboardForm$ButtonContainer(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: DashboardFormCss.buttonContainer
            });
}

var ButtonContainer = {
  make: DashboardForm$ButtonContainer
};

var Css;

export {
  Css ,
  SectionTitle ,
  FieldContainer ,
  FieldContainer25 ,
  MultiFieldContainer ,
  ButtonContainer ,
}
/* DashboardFormCss Not a pure module */
